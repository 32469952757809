import React from 'react';
import 'moment/locale/ru';
import moment from 'moment';
import {RouterProvider} from 'react-router-dom';

import router from './navigation/routes';
import {usePages} from "./models/pages/pages-provider";
import LoadingPage from "./pages/loading/loading.page";
moment.locale('ru');

function App() {
  const {
    data,
    isLoading,
  } = usePages();

  if (isLoading) {
    return <LoadingPage/>;
  }

  return <RouterProvider router={router(data!)} />;
}

export default App;
