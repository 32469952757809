import {Carousel} from 'primereact/carousel';
import {Button} from 'primereact/button';
import {FC} from 'react';
import {clsx} from 'clsx';
import {ICarouselComponentProps, ICarouselScreenEntity} from './carousel.interface';

import {centerOfScreen} from '../../styles/prime-flex-classes.constants';

export const CarouselComponent: FC<ICarouselComponentProps> = ({ items }) => {
  const screenTemplate = (cfg: ICarouselScreenEntity) => {
    const {
      image,
      // route,
      subTitle,
      title,
      buttonText,
    } = cfg;
    return (
      <div
        className={clsx('min-w-full min-h-full bg-cover', centerOfScreen)}
        style={{
          backgroundImage: `url("${image}")`,
        }}
      >
        <div
          // className={'w-full'}
          style={{
            margin: '0 120px',
          }}
        >
          <div
            className={'flex flex-column slider-text bg-black-alpha-40 p-5 border-round-lg'}
          >
            <span
              className={'slider-header'}
            >
              {title}
            </span>
            <span
              className={'max-w-30rem'}
            >
              {subTitle}
            </span>
            <div
              className={'mt-5'}
            >
              <Button
                label={buttonText}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Carousel
      value={items}
      numVisible={1}
      numScroll={1}
      // responsiveOptions={responsiveOptions}
      showIndicators={false}
      itemTemplate={screenTemplate}
      pt={{
        itemsContainer: { className: 'min-h-screen' },
        previousButton: { className: 'absolute z-1 bg-white' },
        nextButton: { className: 'absolute z-1 right-0 bg-white' },
      }}
    />
  );
}
