import * as R from 'ramda';

import {createBrowserRouter, Outlet, RouteObject} from 'react-router-dom';
import {HomePage} from '../pages/home/home.page';
import {NavigationComponent} from '../components/navigation/navigation.component';
import React from 'react';
import UserProvider from '../models/user/user-provider';
import DocumentTypeProvider from '../models/document-type/document-type-provider';
import ArticlesProvider from "../models/articles/articles-provider";
import {PageEntityT} from "../models/pages/pages-provider";
import UniversalPage from "../components/universal-page/univarsal-page";

const ProvidersLayout = () => (
  <UserProvider>
    <DocumentTypeProvider>
      <ArticlesProvider>
        <NavigationComponent/>
        <Outlet/>
      </ArticlesProvider>
    </DocumentTypeProvider>
  </UserProvider>
);

const mapPageToRoute = (page: PageEntityT, list: PageEntityT[]): RouteObject => ({
  path: page.url,
  element: (page.hasArticle || page.hasDocuments) ? <UniversalPage entity={page}/> : null,
  children: list.filter((itm) => itm.parentId === page.id).map((itm) => mapPageToRoute(itm, list)),
});

const router = (pages: PageEntityT[]) => {
  const tree = (pages ?? []).filter((page) => R.isNil(page.parentId)).map((page) => mapPageToRoute(page, pages));
  return createBrowserRouter([
    {
      element: <ProvidersLayout/>,
      children: [
        {
          path: '/',
          element: <HomePage/>,
        },
        ...tree,
      ],
    },
  ]);
};
//
// const router = createBrowserRouter([
//   {
//     element: <ProvidersLayout/>,
//     children: [
//       {
//         path: '/',
//         element: <HomePage/>,
//       },
//       {
//         element: <DocumentProvidersLayout/>,
//         path: 'documents',
//         children: [
//           {
//             path: 'disclosure',
//             element: <DisclosurePage/>,
//           },
//         ],
//       },
//       {
//         path: 'company',
//         children: [
//           {
//             path: 'about',
//             element: <AboutPage/>,
//           },
//         ],
//       },
//     ],
//   },
// ]);

export default router;
