import {ICommonComponentProps} from "../../definitions/common.definitions";
import {PageEntityT} from "../../models/pages/pages-provider";
import {useGet} from "../../transport/useGet";
import {ArticleEntityT} from "../../models/articles/articles-provider";
import {EndpointsEnum} from "../../constants/endpoints";
import {sanitizeContent} from "../../utils/html";
// import {Image} from "primereact/image";
import {InfoPageLayout} from "../info-page-layout/info-page-layout.component";

export type UniversalPagePropsT = ICommonComponentProps & {
  entity: PageEntityT;
}

const UniversalPage = (props: UniversalPagePropsT) => {
  const {
    entity,
  } = props;

  const {
    hasArticle,
    articleId,
  } = entity;

  const {
    data: article,
    isLoading: articleLoading,
  } = useGet<ArticleEntityT>({
    key: hasArticle ? [EndpointsEnum.ARTICLES, articleId].join('/') : null,
    auth: false,
  });

  return (
    <InfoPageLayout
      title={article?.title ?? ''}
      progress={articleLoading}
    >
      <span
        dangerouslySetInnerHTML={sanitizeContent(article?.content ?? '')}
      />
      {/*<div*/}
      {/*  className={'flex flex-column align-items-center'}*/}
      {/*>*/}
      {/*  <Image*/}
      {/*    src={'/images/145035.jpg'}*/}
      {/*    width={'100%'}*/}
      {/*  />*/}
      {/*</div>*/}
    </InfoPageLayout>
  );
}

export default UniversalPage;