import React from 'react';
import {FC} from 'react';
import styles from './navigation.module.css';
import {MegaMenu} from 'primereact/megamenu';
import {Avatar} from 'primereact/avatar';
import {clsx} from "clsx";
import {ICommonComponentProps} from '../../definitions/common.definitions';
import {Link, useNavigate} from 'react-router-dom';
import {NAVIGATION_ITEMS} from '../../constants/navigation';
import {usePages} from "../../models/pages/pages-provider";

export interface INavigationComponentProps
  extends ICommonComponentProps {
}

export const NavigationComponent: FC<INavigationComponentProps> = (props) => {
  const navigate = useNavigate();
  const {
    data = [],
  } = usePages();

  const endElement = (
      <div
        className={'flex flex-row'}
      >
        {/*<div*/}
        {/*  className={clsx(styles['navigation-search-field'], 'p-inputgroup flex-1')}*/}
        {/*>*/}
        {/*  <span className="p-inputgroup-addon border-round-left-2xl">*/}
        {/*    <i className="pi pi-search"></i>*/}
        {/*  </span>*/}
        {/*  <InputText*/}
        {/*    placeholder={'Поиск'}*/}
        {/*    pt={{*/}
        {/*      root: { className: 'border-round-right-2xl' }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
        <Avatar
          icon={'pi pi-user'}
          size={'large'}
          shape={'circle'}
          className={clsx(styles['navigation-avatar'], 'ml-3')}
        />
      </div>
    );

  const logoElement = (
    <Link
      to={'/'}
    >
      <img
        src={'/images/logo80.png'}
        width={80}
        height={80}
        alt={'Электрические сети Сибири'}
        style={{ marginRight: 16 }}
        className={styles['navigation-logo']}
      />
    </Link>
  );

  const getMenuItemStyle = (context: any) => {
    switch (true) {
      case context.focused:
        return styles['menu-action-focused'];
      case context.active:
        return styles['menu-action-active'];
      default:
        return styles['menu-action'];
    }
  }

  return (
    <div
      className={styles['navigation-wrapper']}
    >
      <MegaMenu
        model={NAVIGATION_ITEMS({ navigate, pages: data })}
        start={logoElement}
        end={endElement}
        breakpoint={'1256px'}
        orientation={'horizontal'}
        pt={{
          root: {
            className: styles['menu-wrapper'],
          },
          menu: {
            className: styles['menu'],
          },
          // @ts-ignore
          action: ({ context }) => ({
            className: getMenuItemStyle(context),
          }),
          // @ts-ignore
          headerAction: ({ context }) => ({
            className: getMenuItemStyle(context),
          }),
          label: {
            className: styles['menu-label'],
          },
          panel: {
            className: styles['menu-panel'],
          },
          submenuHeader: {
            className: styles['menu-submenu-header'],
          },
          submenuIcon: {
            className: styles['menu-action-icon'],
          },
          // @ts-ignore
          menubuttonicon: {
            className: styles['menu-action-popup-icon'],
          }
        }}
      />
    </div>
  );
}
