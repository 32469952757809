import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import {PrimeReactProvider} from "primereact/api";
import AuthProvider from "./providers/auth/auth-provider";
import MessageProvider from "./providers/message/message-provider";
import TransportProvider from "./transport/transport-provider";
import PagesProvider from "./models/pages/pages-provider";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <AuthProvider>
        <MessageProvider>
          <TransportProvider>
            <PagesProvider>
              <App />
            </PagesProvider>
          </TransportProvider>
        </MessageProvider>
      </AuthProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
